import React from "react";
import Layout from "../components/Layout";
import { Row } from "react-bootstrap";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import { data } from "../data/blogPostData/blogData";
import { blogPosts } from "../data/blogposts";

import ExploreButtons from "../components/ExploreButtons/ExploreButtons";
const VideoBlogPost = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  const { link } = data[i];

  return (
    <Layout title={blogPosts[i].blogTitle} description="">
      <div className="top"></div>
      <article className="article-blog-post">
        <Row className="article-blog-post--header">
          <h1>{blogPosts[i].blogTitle}</h1>
          <div className="margin-top-bottom">
            <small>{blogPosts[i].blogDate}</small>
          </div>
        </Row>
        <VideoPlayer link={link} />
        <ExploreButtons id={i} />
      </article>
    </Layout>
  );
};

export default VideoBlogPost;
